@use '@angular/material' as mat;

@import 'primeflex/primeflex.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@include mat.core();
$envoy-palette: (
        50 : #E6F4FC,
        100 : #b3d8ed,
        200 : #80bee2,
        300 : #4da3d6,
        400 : #2690cd,
        500 : #007cc4,
        600 : #0074be,
        700 : #0069b6,
        800 : #005faf,
        900 : #004ca2,
        A100 : #cce0ff,
        A200 : #007cc4,
        A400 : #66a3ff,
        A700 : #4d94ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$envoy-palette-accent: (
        50: #FFFFFF,
        100: #FFFFFF,
        200: #FFFFFF,
        300: #FFFFFF,
        400: #FFFFFF,
        500: #FFFFFF,
        600: #FFFFFF,
        700: #FFFFFF,
        800: #FFFFFF,
        900: #FFFFFF,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: rgba(black, 0.87),
                400: rgba(black, 0.87),
                500: #808FA4,
                600: #808FA4,
                700: #808FA4,
                800: #808FA4,
                900: #808FA4,
                A100: rgba(black, 0.87),
                A200: rgba(black, 0.87),
                A400: rgba(black, 0.87),
                A700: rgba(black, 0.87),
        )
);

$envoy-primary: mat.define-palette($envoy-palette, A200, A100, A400);
$envoy-accent: mat.define-palette($envoy-palette-accent);
$envoy-warn: mat.define-palette(mat.$red-palette);

$envoy-font: mat.define-typography-config(
        $font-family: 'Inter'
);

$envoy-theme: mat.define-light-theme((
        color: (
                primary: $envoy-primary,
                accent: $envoy-accent,
                warn: $envoy-warn,
                text: #ff0000
        ),
        typography: $envoy-font
));

@include mat.all-component-themes($envoy-theme);
@include mat.typography-hierarchy($envoy-font);
@include mat.button-typography($envoy-font);

:root {
  --primary-color: #007cc4;
}

// Form-Field styling
.mat-form-field {
  &-appearance-outline, &-appearance-fill {
    .mdc-text-field--no-label {
      .mat-mdc-form-field-infix .mdc-text-field__input {
        color: #363F45;

        &::placeholder {
          color: #808FA4;
        }
      }
    }

    .mat-mdc-select {
      --mat-select-placeholder-text-color: #808FA4;
      --mat-select-enabled-trigger-text-color: #363F45;
    }
  }

  &-appearance-outline {
    --mat-form-field-container-vertical-padding: 14px;

    .mdc-text-field--outlined {
      --mdc-outlined-text-field-focus-label-text-color: #007cc4;
      --mdc-outlined-text-field-container-shape: 8px;

      &.mdc-text-field--no-label {
        padding: 0 16px 0 12px;
      }

      &.mdc-text-field--disabled {
        background-color: #F2F2F2;

        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__notch {
          border: unset;
        }
      }

      &:not(.mdc-text-field--disabled) {
        &.mdc-text-field--focused {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__trailing,
          .mdc-notched-outline__notch {
            border-color: var(--mdc-theme-primary, #007cc4);
          }
        }

        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__notch {
          border-color: #DFE9F5;
        }

        &:not(.mdc-text-field--focused):hover .mdc-notched-outline {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__trailing,
          .mdc-notched-outline__notch {
            border-color: #b3d8ed;
          }
        }
      }
    }
  }

  &-appearance-fill {
    .mdc-text-field--filled {
      --mat-form-field-container-vertical-padding: 14px;
      padding: 0 16px 0 12px;
      border-radius: 8px;

      &:not(&--disabled) {
        background-color: #F2F2F2;

        .mdc-line-ripple {
          display: none;
        }
      }
    }
  }
}

// Stepper styling
.mat-stepper-horizontal {
  .mat-horizontal-content-container {
    padding-top: 1.5rem;
  }

  .mat-horizontal-stepper-content {
    border-radius: 24px;
    box-shadow: 0 0 8px 0 rgba(214, 220, 232, 0.50);
  }

  .mat-step-header {
    background: unset !important;

    .mat-step-label {
      &-active {
        color: #808FA4;
      }

      &-selected {
        color: #007CC4;
      }
    }

    .mat-step-icon {
      width: 40px;
      height: 40px;
      color: #808FA4;
      box-sizing: border-box;
      background-color: #F2F2F2;

      &-selected {
        font-weight: bold;
        color: mat.get-color-from-palette($envoy-primary);
        background-color: mat.get-color-from-palette($envoy-primary, 50);
        border: 1px solid mat.get-color-from-palette($envoy-primary);
      }

      &-state-done {
        background-color: #D8FFD5;

        + .mat-step-label-active {
          color: #363F45;
        }
      }

      .mat-icon {
        width: 24px;
        height: 24px;
      }
    }

    &:hover:not([aria-disabled]) {
      background-color: transparent;
    }

    &:has(.mat-step-icon-state-done) {
      &::before, &::after, + .mat-stepper-horizontal-line {
        border-top-width: 2px !important;
        border-top-color: #34C759;
      }

      ~ .mat-step-header[aria-selected=true] {
        &:before {
          border-image-slice: 1;
          border-top-width: 2px !important;
          border-image-source: linear-gradient(90deg, #34C759 0%, #007CC4 96.85%);
        }
      }
    }

    &[aria-selected=true] {
      + .mat-stepper-horizontal-line + .mat-step-header {
        &:has(.mat-step-icon-state-done) {
          &::before {
            border-top-width: 1px !important;
            border-color: var(--mat-stepper-line-color);
          }
        }
      }
    }
  }

  &.mat-stepper-label-position-bottom {
    .mat-horizontal-stepper-header {
      &::before, &::after {
        top: 44px;
      }
    }

    .mat-stepper-horizontal-line {
      top: 44px;
    }
  }
}

// Chip list styling
.mat-mdc-chip-listbox {
  .mat-mdc-standard-chip {
    flex: 1;
    height: 45px;
    border-radius: 59px;
    --mdc-chip-elevated-container-color: #F9F9F9;
    --mdc-chip-elevated-disabled-container-color: #F9F9F9;
    --mdc-chip-label-text-color: #363F45;

    .mdc-evolution-chip__cell {
      justify-content: center;

      .mat-mdc-chip-action {
        flex: 1;

        .mat-mdc-chip-graphic {
          flex: unset;
        }
      }
    }

    &.mat-primary.mat-mdc-chip-selected,
    &.mat-primary.mat-mdc-chip-highlighted {
      --mdc-chip-label-text-color: #007CC4;
      --mdc-chip-elevated-container-color: #E6F4FC;
      --mdc-chip-with-icon-selected-icon-color: #007CC4;
      border: 1px solid #00A1FF;
    }

    .mat-mdc-chip-focus-overlay {
      background: unset;
    }
  }
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-container-shape: 8px !important;
  --mdc-filled-button-disabled-container-color: #DFE9F5;
  --mdc-filled-button-disabled-label-text-color: #808FA4;
}

.card-header {
  display: flex;
  align-items: center;

  &__icon {
    width: 30px;
    height: 30px;
    display: inline-flex;
    background-color: #F2F2F2;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 3px;

    .mat-icon {
      width: 20px;
      height: 20px;
    }
  }

  > h2, > h3 {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 0;
    margin-inline-start: 1rem;
  }
}

// Slide Toggle styling
.mat-mdc-slide-toggle {
  --mdc-switch-selected-handle-color: #FFFFFF;
  --mdc-switch-selected-focus-state-layer-color: #0074be;
  --mdc-switch-selected-hover-state-layer-color: #0074be;
  --mdc-switch-selected-pressed-state-layer-color: #F1F1F1;
  --mdc-switch-selected-focus-handle-color: #F1F1F1;
  --mdc-switch-selected-hover-handle-color: #F1F1F1;
  --mdc-switch-selected-pressed-handle-color: #F1F1F1;
  --mdc-switch-selected-focus-track-color: #4da3d6;
  --mdc-switch-selected-hover-track-color: #4da3d6;
  --mdc-switch-selected-pressed-track-color: #4da3d6;
  --mdc-switch-selected-track-color: #00A1FF;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #F1F1F1;
  --mdc-switch-unselected-focus-state-layer-color: #F1F1F1;
  --mdc-switch-unselected-focus-track-color: #DFE9F5;
  --mdc-switch-unselected-handle-color: #fff;
  --mdc-switch-unselected-hover-handle-color: #F1F1F1;
  --mdc-switch-unselected-hover-state-layer-color: #F1F1F1;
  --mdc-switch-unselected-hover-track-color: #DFE9F5;
  --mdc-switch-unselected-icon-color: #616161;
  --mdc-switch-unselected-pressed-handle-color: #F1F1F1;
  --mdc-switch-unselected-pressed-state-layer-color: #F1F1F1;
  --mdc-switch-unselected-pressed-track-color: #DFE9F5;
  --mdc-switch-unselected-track-color: #DFE9F5;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  color: #363F45;
  font-family: 'Inter', sans-serif;
}

.mat-drawer-backdrop {
  position: fixed !important;
}

.mat-mdc-button > .mat-icon {
  height: auto !important;
  padding: 10px 0;
  line-height: 0;
}

.flex.center {
  align-items: center;
  justify-content: center;
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col-12.center {
  height: 90vh;
}

input {
  border: 1px solid #E6F2FA;
  border-radius: 12px;
  height: 48px;
  padding: 1% 3.5%;
}

button.button {
  background: #DFE9F5;
  border-radius: 12px;
  margin: 4px 0 !important;
  border: 1px solid #DFE9F5;
}

.active {
  color: #007CC4 !important;
  font-weight: 700 !important;
}

@import 'mapbox-gl/dist/mapbox-gl.css';
.message-input .mdc-text-field .mdc-notched-outline > * {
  border-color: #DFE9F5;
}

.message-input .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.message-input .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

:host(mat-spinner) {
  circle, .mat-spinner circle {
    stroke: #007CC4 !important;
  }
}

.mat-divider {
  clear: both;
}

.mat-sort-header-container {
  border-bottom: none !important;
}

.inner-sidenav {
  width: 40%;
}

.sidebar-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.sidebar-section .col-6 {
  min-height: 70px;
}

.sidebar-section span {
  color: #808FA4;
}

.sidebar-section .col-2 * {
  font-size: 11px;
}

.sidebar-section h3 {
  margin: 0;
}

.sidebar-title-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.sidebar-title-icon h2, .sidebar-title-icon h4 {
  margin: 0 0 0 10px;
  font-weight: 700;
}

.table.border {
  padding: 20px;
  border: 1px solid #DFE9F5;
  border-radius: 16px;
  overflow-wrap: anywhere;
}

.table.border > .col-12 {
  margin-bottom: 20px;
}

.close-trip {
  display: flex;
  padding: 5px !important;
}

.close-trip:hover {
  background-color: #ddd;
  border-radius: 10px;
  cursor: pointer;
}

.sidebar-header {
  padding: 20px 10px;
  display: flex;
}

.col-6, .w-6 {
  &.align-right {
    display: flex;
    margin-block-start: auto;
    justify-content: flex-end;
    padding: 5px;
    align-items: center;
  }
}

mat-sidenav-content.mat-drawer-content.mat-sidenav-content {
  background-color: #fff;
  height: calc(100% - 5px);
}

.list-header-counts {
  display: flex;
  justify-content: space-around;
}

.list-header-counts > div {
  padding: 40px 30px !important;
}

.list-header-counts .count {
  padding: 12px;
  border-radius: 16px;
  box-shadow: 0 0 4px rgba(214, 220, 232, 0.5);
}

.list-header-counts .col-3 h2, .list-header-counts .col-4 h2 {
  display: flex;
  align-items: center;
}

.list-header-counts .col-3 h2 mat-icon, .list-header-counts .col-4 h2 mat-icon {
  padding-right: 10px;
  width: 40px;
  height: 40px;
}

.mobile.list-header-counts {
  .col-3 h2 mat-icon, .col-4 h2 mat-icon {
    padding-right: 10px;
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
}

.mobile .mdc-button.button-border {
  margin-bottom: 15px;
}

.list-header-counts .col-3 h2, .list-header-counts .col-4 h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #363F45;
}

.list-header-counts .col-3 h1, .list-header-counts .col-4 h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #363F45;
  margin-left: 50px;
}

.drag-list {
  width: 100%;
  max-width: 100%;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.drag-box {
  padding: 3px 5px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  border: 1px solid #DFE9F5;
  border-radius: 8px;
  margin-bottom: 5px;
}

.displayed .drag-box {
  background: #E6F4FC;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-box.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.col-6.align-right, .w-6.align-right {
  h3 {
    color: #007CC4;
  }
}

.icon-button {
  margin: 0;
  min-width: 40px !important;
}

.input-field {
  width: 350px;
}

.input-field .mdc-text-field {
  background-color: #fff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
}

.input-field mat-label {
  font-weight: 500;
  font-size: 12px;
}

.input-field .mat-mdc-form-field-infix {
  padding: 10px 5px !important;
  min-height: auto !important;
}

.input-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px !important;
}

.detail-page .mat-mdc-form-field-flex {
  height: 40px;
}

.input-field.detail-page .mat-mdc-form-field-infix {
  padding: 7px 5px !important;
  min-height: auto !important;
}

.input-field.detail-page .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 15px !important;
}

.icon-button > .mat-icon {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.sidebar-title-icon h5 {
  margin: 0 10px;
}

.mdc-button.button-border {
  border: 1px solid #DFE9F5;
  border-radius: 16px;
  margin: 0 16px;
  color: #808FA4 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  padding: 12px 32px;
}

.filter-button {
  height: 32px !important;
  margin-right: 16px;

  &, &-large {
    min-width: 125px !important;
    display: inline-flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 6px 12px !important;
    background: #F2F2F2 !important;
    border: 1px solid #DFE9F5 !important;
    border-radius: 222px !important;
    margin-bottom: 10px;

    .mdc-button__label {
      width: 100%;
    }
  }

  &-large {
    width: 100% !important;
  }
}

.mdc-button.button-border.true {
  background-color: #1a7cc4;
  color: #fff !important;
}

.mat-success {
  color: #fff !important;
  background: #34C759 !important;
}

.mat-success span {
  color: #fff !important;
}

.mat-warning {
  color: #fff !important;
  background: #FF3B30 !important;
}

.mat-warning span {
  color: #fff !important;
}

.mat-blue {
  color: #fff !important;
  background: #007CC4 !important;
}

.mat-blue span {
  color: #fff !important;
}

h4.box-border {
  background: #E6F4FC;
  border-radius: 32px;
  padding: 8px;
  width: 50%;
  text-align: center;
  margin: 5px;
  color: #007CC4;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.sidebar-header h3 {
  margin: 0 !important;
}

.icon-more-button {
  color: #007CC4;

  &:hover {
    cursor: pointer;
    background-color: #ddd;
    border-radius: 5px;
  }
}

.warning * {
  color: #FF3B30;
}

.success * {
  color: #34C759;
}

.blue * {
  color: #007CC4;
}

.mdc-snackbar.error > div {
  background-color: #c14343;
}

.mdc-snackbar.success > div {
  background-color: darkgreen;
}

.inner-sidenav.mobile {
  width: 100%;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  width: 10px;
  height: 10px;
  top: 5px;
  right: -5px !important;
}

.mat-mdc-menu-item.mat-mdc-focus-indicator.mdc-list-item.not_read {
  background-color: #f0f0f0;
}

@media screen and (max-width: 480px) {
  .list-header-counts .col-3 h2, .list-header-counts .col-4 h2 {
    font-size: 17px;
  }
}

@media screen and (max-width: 360px) {
  .list-header-counts .col-3 h2, .list-header-counts .col-4 h2 {
    font-size: 15px;
  }
}

.container {
  padding: 0 16px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  background: #F9F9F9;
  border-radius: 8px;
  border: 1px solid #DFE9F5;

  .nav-item {
    --mdc-text-button-label-text-weight: 400;
    --mdc-text-button-label-text-color: #627186;
  }

  > h4 {
    margin: 0;
    color: #363F45;
    font-weight: 500;
    margin-inline-start: 0.5rem;
  }
}

.empty-table-message {
  border-right: 1px solid #DFE9F5;
  border-left: 1px solid #DFE9F5;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
  color: #B6BFCC;

  .mat-icon {
    width: 250px;
    height: 250px;
  }

  > h3 {
    margin-bottom: 0;
  }
}

.mat-divider {
  --mat-divider-color: #DFE9F5;
}

.header-statistics {
  .statistics-item {
    display: flex;
    gap: 10px;
    color: #363F45;
    padding: 0.75rem;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(214, 220, 232, 0.50);
    box-sizing: border-box;
    height: 100%;

    .mat-icon {
      width: 40px;
      height: 40px;
    }

    &__title {
      > h2 {
        line-height: 40px;
      }

      > h1, h2 {
        font-weight: 600;
      }
    }
  }
}

.sidenav {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;

    > h3 {
      flex: 1;
      padding: 5px;
      margin-bottom: 0;
    }

    .title {
      color: #007CC4;
      text-align: end;
    }

    .close-btn {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      color: #808FA4;

      &:hover {
        background-color: #ddd;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    color: #363F45;

    .section {
      padding: 1.25rem;

      .header-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title-icon {
          margin-bottom: 0 !important;
        }

        .mat-mdc-icon-button {
          --mdc-icon-button-state-layer-size: 32px;
          width: var(--mdc-icon-button-state-layer-size);
          height: var(--mdc-icon-button-state-layer-size);
          padding: 4px;
        }
      }

      .title-icon {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        line-height: 19px;
        font-weight: 700;
        color: #363F45;
        gap: 10px;
      }

      .content {
        padding: 1rem;
        margin: 1rem 0;
        border-radius: 16px;
        border: 1px solid #DFE9F5;
        overflow-wrap: anywhere;

        .label {
          color: #808FA4;
          display: inline-block;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

%sm-form-field, .sm-form-field {
  --mat-form-field-container-text-size: 12px;
  --mat-form-field-container-text-weight: 500;
  --mdc-outlined-text-field-label-text-size: 12px;
  --mdc-outlined-text-field-label-text-weight: 500;
  --mat-form-field-container-text-line-height: 16px;

  &.mat-mdc-form-field-type-mat-select {
    --mat-select-trigger-text-size: 14px;
    --mat-select-trigger-text-weight: 500;
    --mat-form-field-container-vertical-padding: 10px !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    padding: 8px 0;
  }

  .mdc-text-field {
    border-radius: 8px;

    .mdc-floating-label {
      &:not(&--float-above) {
        top: 50% !important;
      }
    }

    .mat-mdc-form-field-infix {
      min-height: auto !important;
    }

  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.list {
  &-container {
    padding: 0 0.5rem 1.5rem;

    .mat-mdc-paginator {
      &-container {
        padding-top: 4px;
        padding-bottom: 10px;
        background-color: #F9F9F9;
        border: 1px solid #DFE9F5;
        border-top-color: #F2F2F2;
        justify-content: space-between;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      &-range-actions {
        @media screen and (min-width: 768px) {
          justify-content: flex-end;
          width: calc(50% + 35px);

          .mat-mdc-paginator-range-label {
            margin-right: auto;
          }
        }
      }

      &-page-size-select {
        background-color: #ffffff;
        border-radius: 8px;
      }
    }

    .text-secondary {
      font-weight: 500;
      color: #627186;
    }
  }

  &-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    gap: 1.5rem;

    .search-box {
      flex-basis: 30%;
      @extend %sm-form-field;
    }

    &__actions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .secondary-btn {
        margin-inline-end: .5rem;

        @media screen and (max-width: 575px) {
          flex-basis: 100%;
          margin-bottom: 1rem;
        }
      }

      .mat-divider-vertical {
        margin-inline: .25rem;
      }

      .mat-mdc-button {
        min-width: 48px;
        align-self: center;

        &.active {
          min-width: auto;
          --mdc-text-button-container-shape: 8px;
          background-color: #E6F4FC;
          border: 1px solid #007cc4;
        }

        > .mat-icon {
          width: 24px;
          margin-right: 0;
        }
      }
    }
  }

  &-table-container {
    overflow-x: scroll;
  }

  &-table.mat-mdc-table {
    border-radius: 16px 16px 0 0;
    border-color: #DFE9F5;
    border-style: solid;
    border-width: 1px;
    border-bottom-width: 0;
    --mat-table-header-headline-color: #627186;
    --mat-table-row-item-label-text-color: #363F45;

    .mat-mdc-cell {
      border-bottom: unset;

      .small {
        display: block;
        font-size: 12px;
        font-weight: 500;
        color: #808FA4;
      }
    }

    .mat-mdc-row.striped-row {
      background-color: #F9F9F9;
    }

    thead {
      background: unset;
    }

    .mat-mdc-row:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      & .mat-mdc-cell {
        &:first-child {
          border-bottom-left-radius: 16px;
        }

        &:last-child {
          border-bottom-right-radius: 16px;
        }
      }
    }

    .mat-mdc-icon-button {
      --mdc-icon-button-icon-size: 18px;
      --mdc-icon-button-state-layer-size: 30px;
      border-radius: 8px !important;
      padding: 6px 0;

      > .mat-icon {
        width: 18px;
        height: 18px;
      }

      .mat-mdc-button-persistent-ripple {
        border-radius: 8px !important;
      }

      .mat-mdc-button-touch-target {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.secondary-btn {
  --mdc-filled-button-container-color: #E6F4FC !important;
  --mdc-filled-button-label-text-color: #007CC4 !important;
  --mat-filled-button-ripple-color: rgba(0, 124, 196, 0.1) !important;
}

.envoy-dialog {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 16px;
    --mdc-dialog-subhead-color: #363F45;

    .mdc-dialog__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 16px !important;
      border-bottom: 1px solid #DFE9F5;

      &::before {
        display: none;
      }

      .title-icon {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .mat-icon {
          margin-inline-end: 0.5rem;
        }
      }

      .mat-mdc-button > .mat-icon {
        padding: 0;
        width: 34px;
        height: 34px;
        margin-right: 0;
      }
    }

    .mdc-dialog__surface {
      padding: 1rem 0;
    }
  }

  .mdc-dialog__actions {
    border-top: 1px solid #DFE9F5;
  }
}

.mat-datepicker-content {
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0px 4px 5px 0px rgba(0, 0, 0, .14), 0px 1px 10px 0px rgba(0, 0, 0, .12);

  // Removes the circles around the dates
  .mat-calendar-body-cell-content {
    border: none;

    // When date was hovered, this is what it will look like
    &:hover {
      background: #DFE9F5;
    }

    // When date was selected, this is what it will look like
    &.mat-calendar-body-selected {
      background: #007cc4;
      color: #fff;
    }
  }

  .time-container {
    padding: 0.5rem 0;
  }

  .ngx-mat-timepicker-table {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.envoy-primary-snackbar.mdc-snackbar {
  --mdc-snackbar-container-color: #E6F4FC;
  --mdc-snackbar-supporting-text-weight: 500;
  --mdc-snackbar-supporting-text-color: #363F45;
  --mdc-snackbar-container-shape: 8px;

  .mdc-snackbar__surface {
    box-shadow: 0 12px 16px 0 #6D738140;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30%;
      height: 6px;
      background-color: #007cc4;
      pointer-events: none;
      box-sizing: border-box;
      border-bottom-left-radius: 8px;
    }
  }
}
